.not_found {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  background: #fff;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 99999;
  padding: 50px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  p {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #b9bdcd;
    line-height: 22px;
    letter-spacing: 1px;
    margin-top: 56px;
  }
}

@primary-color: #422CEA;