.about_block .head_block {
  position: relative;
  z-index: -1;
  min-height: 9.6rem;
}

.about_block .head_block .head_main {
  position: absolute;
  top: 2.4rem;
  right: 0.533333rem;
  bottom: 1.6rem;
  left: 0.533333rem;
  display: flex;
  flex-flow: column nowrap;
}

.about_block .head_block .head_title {
  margin-bottom: 0.64rem;
  font-size: 0.533333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: #FFFFFF;
  line-height: 0.893333rem;
  text-align: center;
}

.about_block .head_block .head_desc {
  margin-bottom: 0.533333rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 0.48rem;
  word-break: break-all;
}

.about_block .head_block img {
  width: 100%;
}

.about_block .trade_block {
  padding: 1.333333rem 0.533333rem;
}

.about_block .trade_block .title {
  font-size: 0.533333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: #131313;
  line-height: 0.746667rem;
  text-align: center;
}

.about_block .trade_block .title_desc {
  margin-top: 0.106667rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #B9BDCD;
  line-height: 0.533333rem;
  text-align: center;
}

.about_block .trade_block .card {
  margin-top: 0.746667rem;
  padding-bottom: 0.693333rem;
  box-shadow: 0 0 0.106667rem 0.053333rem rgba(19, 19, 19, 0.04);
  border-radius: 0.213333rem;
}

.about_block .trade_block .card_title {
  height: 1.493333rem;
  line-height: 1.493333rem;
  text-align: center;
  font-size: 0.48rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: #FFFFFF;
  background-image: url('./img/us.png');
  background-size: cover;
}

.about_block .trade_block .second_card_title {
  background-image: url('./img/us_1.png');
}

.about_block .trade_block .first_mian {
  margin-top: 0.746667rem;
  font-size: 0.48rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: #131313;
  line-height: 0.666667rem;
  text-align: center;
}

.about_block .trade_block .second_main {
  margin-top: 0.32rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: #131313;
  line-height: 0.56rem;
  text-align: center;
}

.about_block .trade_block .highlight {
  color: #F35353;
  font-size: 0.4rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 0.56rem;
}

.about_block .elite_block {
  padding: 1.333333rem 0.533333rem;
  background: rgba(243, 243, 250, 0.5);
}

.about_block .elite_block .title {
  font-size: 0.533333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: #131313;
  line-height: 0.746667rem;
  text-align: center;
}

.about_block .elite_block .main {
  margin: 0.746667rem 0 0.32rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #131313;
  line-height: 0.533333rem;
  word-break: break-all;
}

.about_block .elite_block .card_list {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 2.693333rem);
  grid-gap: 0.133333rem;
}

.about_block .elite_block .card_item {
  flex: 1 1 2.693333rem;
  /*  flex-grow: 1 ，表示自动延展到最大宽度 */
  flex: 0 1 2.693333rem;
  /*  No stretching: */
  margin-top: 0.426667rem;
}

.about_block .elite_block .card_item img {
  width: 2.693333rem;
}

.about_block .elite_block_ft .title {
  font-size: 0.64rem;
}

.about_block .elite_block_ft .main {
  text-align: center;
}