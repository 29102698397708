.mobile_form {
  padding: 1.6rem 0.533333rem;
  height: 100vh;
}

.mobile_form .ant-input-affix-wrapper:focus {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.mobile_form .ant-input-affix-wrapper:hover {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.mobile_form input {
  caret-color: #422CEA;
}

.mobile_form input:hover {
  border: none;
}

.mobile_form input:focus {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.mobile_form h1 {
  font-size: 0.64rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #131313;
  line-height: 0.893333rem;
  text-align: center;
  margin-bottom: 1.066667rem;
}

.mobile_form .ant-form-item-explain {
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #F35353;
  line-height: 0.44rem;
}

.mobile_form .form_item {
  border-radius: 0.106667rem;
  border: 1Px solid #b9bdcd;
}

.mobile_form .form_phone .form_area_select {
  padding: 0 0.266667rem 0 0.426667rem;
}

.mobile_form .form_phone .form_area_select::before {
  width: 0.026667rem;
  height: 0.64rem;
  background: #f3f3fa;
}

.mobile_form .form_phone .form_area_select .select_val {
  margin-right: 0.066667rem;
}

.mobile_form .form_btn {
  padding-top: 0.96rem;
  margin-bottom: 0.32rem;
}

.mobile_form .form_btn .ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content:after {
  content: '';
  display: none;
}

.mobile_form .form_btn .ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content button {
  height: 1.28rem;
  width: 100%;
  border-radius: 0.106667rem;
  background: #422CEA;
  color: #ffffff;
  line-height: 0.6rem;
  border: none;
  outline: none;
}

.mobile_form .form_btn .ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content button[disabled] {
  background: #B9BDCD;
}

.mobile_form .form_btn .ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content button span {
  font-size: 0.426667rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}

.mobile_form .mobile_form_footer {
  margin: 0;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #5a5a60;
  line-height: 0.44rem;
  text-align: center;
}

.mobile_form .mobile_form_footer a {
  color: #422CEA;
  display: inline;
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 400;
}

.mobile_form .result_message {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 3.733333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  background: #ffffff;
  box-shadow: 0 0.08rem 0.213333rem 0 rgba(19, 19, 19, 0.06);
  border-radius: 0.106667rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}

.mobile_form .result_message p {
  font-size: 0.32rem;
  font-weight: 400;
  color: #131313;
  font-family: PingFangSC-Medium, PingFang SC;
  line-height: 0.426667rem;
  text-align: center;
  margin-top: 0.213333rem;
}

.ant-modal-centered .ant-modal {
  border-radius: 0.053333rem;
}

.mobile_select_mask {
  overflow: hidden;
}

.mobile_select_mask .ant-modal-header {
  padding: 0 0.426667rem;
  border: none;
  height: 1.066667rem;
  line-height: 1.066667rem;
  font-size: 0.333333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 501;
  color: #131313;
  line-height: 0.533333rem;
  display: flex;
  align-items: center;
}

.mobile_select_mask .ant-modal-header .ant-modal-title {
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium, PingFang SC;
}

.mobile_select_mask .ant-modal-body {
  padding: 0;
}

.mobile_select_mask .ant-modal-body .options_wrap {
  height: calc(100vh - 7.066667rem);
  overflow-y: auto;
}

.mobile_select_mask .ant-modal-body .options_wrap .options_list .options_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 1.28rem;
  padding: 0 0.426667rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #131313;
  line-height: 0.533333rem;
}

.mobile_select_mask .ant-modal-body .options_wrap .options_list .options_item.active {
  color: #422cea;
  font-family: PingFangSC-Medium, PingFang SC;
}

.mobile_network_online .ant-message-notice-content {
  height: 1.2rem;
  width: 80%;
  background: rgba(19, 19, 19, 0.8);
  color: #fff;
  border-radius: 0.106667rem;
}

.mobile_network_online .ant-message-notice-content .ant-message-custom-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.live_chat {
  text-align: center;
  margin-top: 1.6rem;
}

.live_chat a {
  text-decoration: underline;
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #422cea;
  line-height: 0.533333rem;
}

.web_send_code button span {
  font-size: 0.426667rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 0.6rem;
}