.rmc-notification {
  top: 50% !important;
  transform: translateY(-50%);
}

.rmc-notification-notice {
  background: rgba(58, 58, 58, 0.9);
  padding: 0.533333rem;
  width: max-content;
  border-radius: 0.053333rem;
}

.rmc-notification-notice-content {
  color: #fff !important;
  font-size: 0.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 0.56rem;
}

.toast_success {
  font-weight: 200;
  font-size: 0.746667rem;
  width: 0.746667rem;
  height: 0.746667rem;
  display: block;
}

.toast_body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toast_loading {
  font-size: 2.25rem;
  width: 2.25rem;
  height: 2.25rem;
  display: inline-block;
  -webkit-transform: rotate(360deg);
  -moz-animation: rotation 1s linear infinite;
  -webkit-animation: rotation 1s linear infinite;
  -o-animation: rotation 1s linear infinite;
}

.toast_tip {
  width: 5.6rem;
  margin-top: 0.306667rem;
  font-size: 0.426667rem;
  font-family: PingFangSC-Medium, PingFang SC;
  text-align: center;
  box-sizing: border-box;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 0.6rem;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}