.mobile_home .mobile_home_page1 {
  width: 100%;
  height: 8.906667rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  background: url('./img/background.png') no-repeat;
  background-size: cover;
}

.mobile_home .mobile_home_page1 .home_page1_head {
  width: 8.493333rem;
}

.mobile_home .mobile_home_page1 .home_page1_head h1 {
  font-size: 0.64rem;
  text-align: center;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.893333rem;
}

.mobile_home .mobile_home_page1 .home_page1_head p {
  margin-top: 0.32rem;
  text-align: center;
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.533333rem;
  letter-spacing: 0.013333rem;
  margin-bottom: 0.853333rem;
}

.mobile_home .mobile_home_page1 .home_page1_btn {
  font-family: PingFangSC-Medium, PingFang SC;
}

.mobile_home .mobile_home_page1 .home_page1_btn a {
  font-family: PingFangSC-Medium, PingFang SC;
}

.mobile_home .mobile_home_page1 .home_page1_btn a button {
  outline: none;
  border: none;
  width: 2.92rem;
  height: 0.96rem;
  background: #ffffff;
  border-radius: 0.48rem;
  font-size: 0.426667rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #422cea;
  line-height: 0.96rem;
}

.mobile_home .home_page2 {
  padding: 1.493333rem 0.533333rem;
  padding-bottom: 0;
}

.mobile_home .home_page2 h1 {
  font-size: 0.533333rem;
  text-align: center;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #131313;
  line-height: 0.746667rem;
  white-space: nowrap;
  margin: 0;
}

.mobile_home .home_page2 .home_page2_content {
  padding-top: 1.386667rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mobile_home .home_page2 .home_page2_content .text_item {
  width: 8.933333rem;
  text-align: center;
  margin-bottom: 2.24rem;
}

.mobile_home .home_page2 .home_page2_content .text_item img {
  height: 4rem;
}

.mobile_home .home_page2 .home_page2_content .text_item .step2_texts {
  margin-top: 0.533333rem;
}

.mobile_home .home_page2 .home_page2_content .text_item .step2_texts p {
  margin: 0;
  font-size: 0.426667rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #131313;
  line-height: 0.666667rem;
}

.mobile_home .home_page3 {
  padding: 1.333333rem 0;
  text-align: center;
  background: url('./img/page4@2x.png') no-repeat;
  background-position: center;
  background-size: cover;
}

.mobile_home .home_page3 .page3_head h1 {
  font-size: 0.533333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.746667rem;
  margin: 0;
}

.mobile_home .home_page3 .page3_head p {
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.533333rem;
  margin: 0;
  margin-top: 0.106667rem;
}

.mobile_home .home_page3 .page3_content img {
  margin: 0.746667rem auto;
  width: 5.76rem;
  display: block;
}

.mobile_home .home_page3 .page3_footer button {
  outline: none;
  border: none;
  width: 3.28rem;
  height: 0.96rem;
  background: #ffffff;
  box-shadow: 0 0 0.16rem 0.026667rem #422cea;
  border-radius: 0.48rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #422cea;
  line-height: 0.533333rem;
}

.mobile_home .home_page4 {
  padding: 1.333333rem 0.533333rem;
}

.mobile_home .home_page4 .page4_head h1,
.mobile_home .home_page4 .page4_head p {
  margin: 0;
}

.mobile_home .home_page4 .page4_head h1 {
  font-size: 0.52rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #131313;
  line-height: 0.746667rem;
}

.mobile_home .home_page4 .page4_head p {
  margin-top: 0.426667rem;
  margin-bottom: 0.746667rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #131313;
  line-height: 0.533333rem;
}

.mobile_home .home_page4 img {
  display: block;
  margin: 0 auto;
  width: 8.506667rem;
}