.down_step1 {
  width: 100%;
  height: 100vh;
  background: url('../../../assets/download/background.svg') no-repeat;
  background-size: cover;
  .step1_content {
    max-width: 1300px;
    margin: 0 auto;
    height: 100%;
    height: calc(100vh - 72px);
    display: flex;
    align-items: center;
    justify-content: center;
    .step1_pic {
      // width: 408px;
      margin-left: 231px;
      height: calc(100vh - 180px);
    }
  }
}

@primary-color: #422CEA;