.home_placeholder {
  width: 100%;
  height: 1.52rem;
}

.home_footer_shop {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.92rem;
  background: #422cea;
  padding: 0 1.28rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home_footer_shop button {
  width: 3.2rem;
  height: 0.96rem;
  border-radius: 0.106667rem;
  border: 1Px solid #ffffff;
  background: none;
  font-size: 0.426667rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.6rem;
}

.home_footer_shop .livechat {
  background: #fff;
  color: #422cea;
}