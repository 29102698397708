.mobile_input {
  width: 100%;
  height: 100%;
  border: none;
  padding-left: 0.666667rem;
  position: relative;
}

.mobile_input .ant-input-affix-wrapper:focus {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.mobile_input .ant-input-affix-wrapper:hover {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.mobile_input input {
  padding: 0;
  caret-color: #422CEA;
}

.mobile_input input:hover {
  border: none;
}

.mobile_input input:focus {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.mobile_input:after {
  display: inline-block;
  position: absolute;
  left: 0.426667rem;
  top: 50%;
  transform: translateY(-30%);
  color: #ff4d4f;
  font-size: 0.426667rem;
  vertical-align: top;
  line-height: 1;
  content: '*';
}

.mobile_input .ant-input-affix-wrapper {
  height: 100%;
}