//勿改变此文件引入，涉及到rem转换问题，需转换的单位勿写入此文件夹内
@import '~antd/dist/antd.less';
@import '../assets/style/normalize.css';
* {
  font-family: PingFangSC-Regular, PingFang SC ;
}
body,
html {
  // overflow-x: hidden;
}

@primary-color: #422CEA;