.down_step2 {
  width: 100%;
  height: calc(100vh - 72px);
  box-sizing: border-box;
  background: #fff;
  // padding-top: 191px;
  // padding: 204px 0;
  padding: 7% 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    text-align: center;
    // margin-top: 191px;
    font-size: 56px;
    font-weight: 500;
    color: #131313;
    line-height: 78px;
    margin: 0;
  }
  .step2_list {
    text-align: center;
    // margin: 80px 340px;
    max-width: 1300px;
    height: 100%;
    margin: 0 auto;
    margin-top: 80px;
    margin-bottom: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    .step2_item {
      // width: 460px;
      text-align: center;
      // margin-bottom: 109px;
      padding: 0 75px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // &:nth-child(3) {
      //   margin-bottom: 0;
      // }
      // &:last-child {
      //   margin-bottom: 0;
      // }
      p {
        margin-top: 24px;
        text-align: center;
        padding: 0 50px;
        font-size: 18px;
        font-weight: 400;
        color: #131313;
        line-height: 25px;
        box-sizing: border-box;
      }
    }
  }
}

@primary-color: #422CEA;