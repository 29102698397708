.mobile_footer {
  padding: 1.333333rem 0.533333rem;
  background: #131313;
  color: #b9bdcd;
}

.mobile_footer .footer_top {
  display: flex;
  margin-bottom: 0.213333rem;
}

.mobile_footer .footer_top .footer_nav {
  flex: 1;
}

.mobile_footer .footer_top .footer_nav a {
  margin-bottom: 0.533333rem;
}

.mobile_footer .footer_top .footer_nav a span {
  color: #b9bdcd;
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 0.533333rem;
}

.mobile_footer .footer_top .footer_icon {
  width: 0.533333rem;
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.mobile_footer .footer_top .footer_icon a:nth-child(1) img {
  width: 0.533333rem;
}

.mobile_footer .footer_top .footer_icon a:nth-child(2) img {
  width: 0.533333rem;
}

.mobile_footer .footer_top .footer_icon a:nth-child(3) img {
  width: 0.6rem;
}

.mobile_footer .footer_top .footer_icon a:nth-child(4) img {
  width: 0.333333rem;
}

.mobile_footer .live_chat {
  width: 3rem;
  height: 0.96rem;
  margin-top: 0;
  border-radius: 0.106667rem;
  border: 1Px solid #b9bdcd;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.746667rem;
}

.mobile_footer .live_chat button {
  background: none;
  outline: none;
  border: none;
  text-align: center;
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #b9bdcd;
  line-height: 0.533333rem;
}

.mobile_footer .footer_service p {
  margin: 0;
  font-size: 0.346667rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #b9bdcd;
  line-height: 0.493333rem;
}

.mobile_footer .f_logo {
  margin-top: 0.746667rem;
  width: 2.653333rem;
}