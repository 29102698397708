.step4_content {
  background: #fff;
  max-width: 1300px;
  height: calc(100vh - 72px);
  margin: 0 auto;

  // padding: 215px 310px 187px;

  box-sizing: border-box;
  display: flex;
  // justify-content: center;
  align-items: center;
  .step4_left {
    img {
      width: 381px;
    }
  }
  .step4_right {
    margin-left: 180px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    h2 {
      //       width: 714px;
      // height: 224px;
      font-size: 40px;
      font-weight: 500;
      color: #131313;
      line-height: 56px;
    }
    p {
      // width: 714px;
      // height: 66px;
      font-size: 24px;
      font-weight: 400;
      color: #131313;
      line-height: 33px;
    }
  }
}
// .ant-image-preview-img-wrapper {
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

@primary-color: #422CEA;