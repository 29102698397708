.download_block {
  word-break: break-all;
}

.download_block .download_main {
  height: 25.6rem;
  padding: 2.213333rem 0.533333rem 1.333333rem;
  box-sizing: border-box;
  background-image: url('./img/background.png');
  background-size: cover;
}

.download_block .download_main .title {
  font-size: 0.52rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 0.746667rem;
  text-align: center;
}

.download_block .download_main .title_desc {
  margin-top: 0.106667rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 0.533333rem;
  text-align: center;
}

.download_block .download_main .list {
  margin-top: 0.746667rem;
}

.download_block .download_main .list_item {
  margin-top: 0.213333rem;
  display: flex;
  flex-flow: row nowrap;
}

.download_block .download_main .list_item_icon {
  margin-right: 0.213333rem;
  width: 0.16rem;
  height: 0.16rem;
  background: #FFFFFF;
  border-radius: 50%;
  transform: translateY(0.163333rem);
}

.download_block .download_main .list_item_text {
  flex: 1;
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 0.533333rem;
  word-break: break-word;
}

.download_block .download_main .phoneIcon {
  display: block;
  margin: 0.746667rem auto;
  width: 7.546667rem;
}

.download_block .download_main .download_btn {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.download_block .download_main .btn {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 0 0.213333rem;
  width: 3.733333rem;
  height: 0.96rem;
  border-radius: 0.48rem;
  border: 1Px solid #FFFFFF;
}

.download_block .download_main .btn_icon {
  width: 0.481733rem;
  height: 0.533333rem;
}

.download_block .download_main .btn_text {
  margin-left: 0.226667rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 0.533333rem;
}

.download_block .download_main_ft .title {
  font-size: 0.64rem;
}

.download_block .download_main_ft .title_desc {
  font-size: 0.426667rem;
}

.download_block .download_main_ft .list {
  margin: 0 auto;
  margin-top: 0.746667rem;
  width: 6.266667rem;
}

.download_block .download_main_ft .why_title {
  font-size: 0.64rem;
}

.download_block .why_main {
  padding: 1.333333rem 0.133333rem 0.933333rem;
}

.download_block .why_main .why_title {
  font-size: 0.533333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: #131313;
  line-height: 0.746667rem;
  text-align: center;
  margin-bottom: 50 px;
}

.download_block .why_main .list {
  margin-top: 0.746667rem;
}

.download_block .why_main .list_item {
  height: 3.36rem;
  padding: 0 1.04rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  box-sizing: border-box;
  background-image: url('./img/rectangular.svg');
  background-size: cover;
}

.download_block .why_main .list_item_icon {
  margin-right: 0.426667rem;
  width: 0.853333rem;
  height: 0.96rem;
}

.download_block .why_main .list_item_text {
  flex: 1;
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #131313;
  line-height: 0.533333rem;
  word-break: break-word;
}