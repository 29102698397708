.about_step3 {
  width: 100%;
  height: calc(100vh - 72px);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .team_elite {
    width: 1300px;
    text-align: center;
    h1 {
      margin: 0;
      font-size: 56px;
      font-weight: 500;
      color: #131313;
      line-height: 78px;
    }
    .team_subtitle {
      font-size: 18px;
      font-weight: 400;
      color: #131313;
      line-height: 25px;
      margin-top: 56px;
    }
    .team_list {
      display: grid;
      margin-top: 80px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      .team_name {
        width: 264px;
        height: 96px;
        margin-bottom: 40px;
        // border: 1px dashed #999;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@primary-color: #422CEA;