.mobile_head {
  position: absolute;
  top: -0.026667rem;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 1.28rem;
  padding: 0 0.533333rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile_head.black {
  background: #131313;
  position: fixed;
}

.mobile_head .mobile_logo {
  width: 2.666667rem;
}

.mobile_head .mobile_nav {
  width: 0.533333rem;
  height: 0.533333rem;
}

.mobile_head .mobile_nav img {
  width: 100%;
  height: 100%;
}

.mobile_head .mobile_nav_mask {
  transform: translateX(100%);
  transition: transform 0.3s;
  padding: 0 0.533333rem;
  position: absolute;
  left: 0;
  z-index: 20;
  top: 1.28rem;
  background: #fff;
  width: 100%;
  height: calc(100vh - 1.266667rem);
}

.mobile_head .mobile_nav_mask .nav_item {
  height: 1.386667rem;
  line-height: 1.386667rem;
  border-bottom: 0.013333rem solid #f3f3fa;
}

.mobile_head .mobile_nav_mask .nav_item span {
  font-size: 0.426667rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #131313;
  line-height: 0.6rem;
}

.mobile_head .mobile_nav_mask .active_nav span {
  color: #422cea;
}

.mobile_head .mobile_nav_mask .ant-collapse-content-box {
  padding-left: 0;
}

.mobile_head .mobile_nav_mask .ant-collapse-content-box .nav_item {
  border: none;
}

.mobile_head .mobile_nav_mask .ant-collapse-header {
  padding: 0;
  height: 1.386667rem;
  line-height: 1.386667rem;
  border-bottom: 0.013333rem solid #f3f3fa;
  font-size: 0.426667rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #131313;
}

.mobile_head .mobile_nav_mask .ant-collapse-header .coll_icon {
  top: 0.533333rem;
}

.mobile_head .mobile_nav_mask .ant-collapse-header .coll_icon img {
  transition: transform 0.2s linear;
  width: 0.32rem;
}