.protocol_block .protocol_head {
  height: 1.28rem;
  background-color: #000;
}

.protocol_block .protocol_content {
  padding: 0.746667rem 0.426667rem 0.786667rem 0.533333rem;
}

.protocol_block .title {
  margin-bottom: 0.746667rem;
  font-size: 0.533333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: #131313;
  line-height: 0.746667rem;
  letter-spacing: 0.026667rem;
}

.protocol_block .content {
  padding-right: 0.106667rem;
  height: 15rem;
  overflow: hidden;
  overflow-y: auto;
}

.protocol_block .content > div > div > p:nth-child(1) {
  display: none;
}

.protocol_block .content > div > div > p:nth-child(2) {
  display: none;
}

.protocol_block .content > div > div > p:nth-child(3) {
  display: none;
}

.protocol_block .content > div > div > p:nth-last-child(2) {
  display: none;
}

.protocol_block .content > div > div > p:last-child {
  display: none;
}

.protocol_block .privacy_content > div > div > p:nth-child(4) {
  display: none;
}

.protocol_block .privacy_content > div > div > p:nth-last-child(2) {
  display: block;
}

.protocol_block .text p,
.protocol_block .text span {
  font-size: 0.373333rem !important;
  font-family: PingFangSC-Regular, PingFang SC !important;
  color: #5A5A60 !important;
  line-height: 0.533333rem  !important;
  margin: 0 !important;
}

.protocol_block ::-webkit-scrollbar {
  width: 0.053333rem;
  height: 0.053333rem;
  background: #dddddd;
}

.protocol_block ::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 0.053333rem;
  transition: all 0.2s;
}

.protocol_block :hover::-webkit-scrollbar-thumb {
  transition: all 0.2s;
  background: hsla(0, 0%, 53%, 0.6);
}

.protocol_block :hover::-webkit-scrollbar-track {
  transition: all 0.2s;
  background: hsla(0, 0%, 53%, 0.1);
}